import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import ERC20ABI from './ContractsABI/ERC20ABI.json';
import ERC721ABI from './ContractsABI/ERC721ABI.json';
import BUYABI from './ContractsABI/BUY_NFTABI.json';
import { ethers } from 'ethers';

import { BUY_NFT, ERC721_ADDRESS, ERC20_ADDRESS, MAX_MINT, CHAIN_ID, WEI_COST  } from './Config';

let USD_PRICE;
// Buttons Styles

export const StyledButton = styled.button`
  border-radius: 4px;
  border: solid;
  font-family: Monse400;                                
  border: 1px solid rgb(204, 204, 204);
  background-color: black;                                                
  color: white;
  width: 200px;
  height: 40px;
  font-size: 16px;
  cursor: pointer;
  padding: 5px;
  margin-top: 5px;
  @media (max-width: 767px) {
    width: 200px;
    font-size: 16px;
  }
`;

export const StyledRoundButton = styled.button`
  border-radius: 2px;
  margin-top: 10px;
  padding-top: 5px;
  border-radius: 25px;
  border: 1px solid rgb(204, 204, 204);
  background-color: transparent;
  font-size: 16px;
  color: rgb(204, 204, 204);
  width: 35px;
  font-size: 22px;
  height: 35px;
  font-family: BabyDoll;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ResponsiveWrapper = styled.div`
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;

// App Global Const

function App() {

  const [account, setaccount] = useState(``);
  const [isWhitelisted, setIsWhitelisted] = useState(true);

  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [mintbtn, setmintbtn] = useState(false);
  const [mintAmount, setMintAmount] = useState(1);
  const [MaxCollection, setMaxCollection] = useState(0);
  const [totalsupply, settotalsupply] = useState("?");
  const [CONFIG, SET_CONFIG] = useState({
    MAX_SUPPLY: 3000,
    WEI_COST: 2000000, 
    GAS_LIMIT: 2000000,
  });

  const getBuyPrice = async () => {
    try {
      let provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const BuyContract = new ethers.Contract(BUY_NFT, BUYABI, signer) 
      const NFTPrice = await BuyContract.price();
      console.log(NFTPrice)
      return NFTPrice;
    } catch (error) {
      console.log(error);
    }
  };

  const calculateUSDPrice = async () => {
    const nftPrice = await getBuyPrice();
    USD_PRICE = nftPrice / 10 ** 6;  // Now updating the global variable
    console.log(USD_PRICE);
  };
  
  calculateUSDPrice();


  const approvetoken = async () => {
    try {
      let provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner(account);
      let totalCostWei = '115792089237316195423570985008687907853269984665640564039457584007913129639935'; // maximum uint256 value
  
      const USDTContract = new ethers.Contract(ERC20_ADDRESS, ERC20ABI, signer);
  
      const approvalTx = await USDTContract.approve(BUY_NFT, totalCostWei, { gasLimit: 1000000 });
      await approvalTx.wait();
      
      setmintbtn(true);
    } catch (error) {
      alert(error);
    }
  };
  

  const checkApproval = async () => {
    try {
      let provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const account = await signer.getAddress();
      console.log(account);
  
      const USDTContract = new ethers.Contract(ERC20_ADDRESS, ERC20ABI, signer);
  
      const allowance = await USDTContract.allowance(account, BUY_NFT);
      console.log(allowance.toString());
  
      if (allowance >= USD_PRICE * 10**6) {
        console.log('Approved');
        setmintbtn(true);
      } else {
        console.log('Not approved');
        setmintbtn(false);
      }
    } catch (error) {
      console.log(error);
      setmintbtn(false);
    }
  };
  



  const checkWhitelist = async () => {
    try {
      let provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const account = signer.getAddress();
  
      // Call your smart contract's function to check if the wallet address is whitelisted
      const BuyContract = new ethers.Contract(BUY_NFT, BUYABI, signer) 
      const onWhitelisted = await BuyContract.whitelist(account);
      
      console.log(onWhitelisted)
      console.log(account)

      if (onWhitelisted == true) {
        setIsWhitelisted(true);
        checkApproval();
      } else {
        setmintbtn(true);
        // setWhitelistMessage("Sorry, your wallet address is not whitelisted.");
      }
      
    } catch (error) {
      console.log(error);
      setmintbtn(false);
      // setWhitelistMessage("An error occurred while checking your whitelist status. Please try again later.");
    }
  };




  const mint = async () => {
    try {
      let provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner(account);
  
      const NFTcontract = new ethers.Contract(BUY_NFT, BUYABI, signer);
      const mintTx = await NFTcontract.buy(mintAmount, { gasLimit: 1000000 });
      const transactionReceipt = await mintTx.wait();
  
      console.log(transactionReceipt);
      if (transactionReceipt.status === 1) {
        const totalsupply = await NFTcontract.totalSupply();
        settotalsupply(totalsupply.toNumber());
        checkApproval();
      }
    } catch (error) {
      alert(error);
      checkApproval();
    }
  };
  


  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > MAX_MINT) {
      newMintAmount = MAX_MINT;
    }
    setMintAmount(newMintAmount);
  };


  // Wallet Connect

  const wallet = async () => {
    let provider = new ethers.providers.Web3Provider(window.ethereum);
    const network = (await provider.getNetwork()).chainId;
    console.log(network);
    checkApproval();
    checkWhitelist();
    
    // Add event listener to detect changes to the user's Ethereum accounts
    window.ethereum.on('accountsChanged', () => {
      window.location.reload();
    });
    
    if (typeof provider !== 'undefined') {
      let provider2 = window.ethereum;
  
      if (network === CHAIN_ID) {
          const NFTdata = new ethers.Contract(ERC721_ADDRESS, ERC721ABI, provider)
          const totalsupply = await  NFTdata.totalSupply();
          const maxsupply = await  NFTdata.MaxCollection();
          settotalsupply(totalsupply.toNumber());
          setMaxCollection(maxsupply.toNumber());
        provider2
          .request({ method: 'eth_requestAccounts' })
          .then((accounts) => {
            setaccount(accounts[0]);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: '0x'.CHAIN_ID }], // chainId must be in hexadecimal numbers
        });
      }
    }
  };
  

  return (
    <s.Screen>
      <s.Container
        flex={1}
        ai={"center"}
        style={{ padding: 5, backgroundColor: "var(--primary)" }}
      >

        <ResponsiveWrapper flex={1} style={{ padding: 5 }} test>
          <s.Container
            flex={2}
            jc={"center"}
            ai={"center"}
            style={{
              backgroundColor: "var(--accent)",
              padding: 5,
              borderRadius: 0,
              border: "0px dashed var(--secondary)",

            }}
          >

            {data.totalSupply >= CONFIG.MAX_SUPPLY ? (
              <>

                <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  The sale has ended.
                </s.TextTitle>
      
              </>
            ) : (
              <>

                <s.TextTitle
                  style={{
                    textAlign: "center",
                    fontSize: 16,
                  }}
                > 
                  Total minted: {totalsupply} / 3000 
                </s.TextTitle>
                
                <s.TextDescription
                  style={{
                    textAlign: "center",
              
                  }}
                >
                  Quantity
                </s.TextDescription>
                <s.SpacerMedium />

                <s.Container ai={"center"} jc={"center"} fd={"row"}>
                  <StyledRoundButton
                    style={{ lineHeight: 0.4 }}
                    disabled={claimingNft ? 1 : 0}
                    onClick={(e) => {
                      e.preventDefault();
                      decrementMintAmount();
                    }}
                  >
                    -
                  </StyledRoundButton>
                  <s.SpacerMedium />
                  <s.TextDescription
                    style={{
                      textAlign: "center",
                      color: "var(--accent-text)",
                    }}
                  >
                    {mintAmount}
                  </s.TextDescription>
                  <s.SpacerMedium />
                  <StyledRoundButton
                    disabled={claimingNft ? 1 : 0}
                    onClick={(e) => {
                      e.preventDefault();
                      incrementMintAmount();
                    }}
                  >
                    +
                  </StyledRoundButton>
                </s.Container>
                <s.SpacerMedium />

                {account === '' ? (
                  <s.Container ai={"center"} jc={"center"}>
                    
                    <StyledButton
                      onClick={(e) => {
                        wallet();
                      }}
                    >
                      Connet Wallet
                    </StyledButton>
                    
                  </s.Container>
                ) : (
                  <>
                
                    <s.Container ai={"center"} jc={"center"} >
                    {
                      !isWhitelisted ? (
                        <StyledButton
                          disabled={true}
                        >
                          Not Whitelisted
                        </StyledButton>

                      ) : !mintbtn ? (
                        <StyledButton
                          disabled={claimingNft ? 1 : 0}
                          onClick={(e) => {
                            e.preventDefault();
                            approvetoken();
                          }}
                        >
                          Approve USDT
                        </StyledButton>
                      ) : (
                        <StyledButton
                          disabled={claimingNft ? 1 : 0}
                          onClick={(e) => {
                            e.preventDefault();
                            mint();
                          }}
                        >
                          Mint ({mintAmount*USD_PRICE} USDT)
                        </StyledButton>
                      )
                    }
                    </s.Container>
                  </>
                )}
              </>
            )}
          </s.Container>
        </ResponsiveWrapper>
      </s.Container>
    </s.Screen>
  );
}


export default App;
