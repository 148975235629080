
// Config the main settings

export const ERC721_ADDRESS = "0x1cA630a4a3757e9198bFBA166300Ce5e9adaeF46"; //  ERC721 Contract Address
export const ERC20_ADDRESS = "0xdAC17F958D2ee523a2206206994597C13D831ec7"; //  ERC20 Contract Address
export const BUY_NFT = "0x2388984769008E094D4CF924790a0679AbBb8972"; //  ERC20 Contract Address


// export const ERC20_ADDRESS = "0xD87Ba7A50B2E7E660f678A895E4B72E7CB4CCd9C"; //  ERC20 TestNet Contract Address
export const USD_PRICE = 1600; 
export const WEI_PRICE = 400000000;
export const MAX_MINT = 3000;  
export const CHAIN_ID = 1;  // ETH: 1, Goerly: 5, Polygon: 137.
